/**
 * スライダー
 */

import Slick from 'slick-carousel';
import $ from 'jquery';

class SlickClass {
    constructor() {
        $('.slider').slick({
            mobileFirst: true,
            variableWidth: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            arrows: true,
            swipeToSlide: true,
            responsive: [ {
                breakpoint: 768,
                settings: 'unslick'
            } ]
        });

        //リサイズした時に実行
        $(window).on('resize orientationchange', function() {
        　　$('.slider').slick('resize');
        });

        $('.top-slider').slick({
            variableWidth: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 800,
            centerMode: true,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            prevArrow: '<div class="slide-arrow prev-arrow"></div>',
            nextArrow: '<div class="slide-arrow next-arrow"></div>',
        });

        $('.top-slider02').slick({
            variableWidth: true,
            infinite: true,
            speed: 800,
            centerMode: true,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            prevArrow: '<div class="slide-arrow prev-arrow"></div>',
            nextArrow: '<div class="slide-arrow next-arrow"></div>'
        });

        $('.job_slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            swipeToSlide: true,
            asNavFor:".job_slider_thumb",
            prevArrow: '<div class="slide-arrow prev-arrow"></div>',
	        nextArrow: '<div class="slide-arrow next-arrow"></div>'
        });

        $('.job_slider_thumb').slick({
            slidesToShow: 4,
            centerMode: true,
            focusOnSelect: true,
            asNavFor:".job_slider"
        });
    }
}

const slick = new SlickClass();
